import React, { Component } from "react";
import Select from "@atlaskit/select";
import { API } from "./../../axios";
import { endpoints } from "./../../endpoints";

class UserSelect extends Component {
  state = {
    options: [],
    filteredOptions: []
  };

  filterOptions(value) {
    if (value) {
      API.GET(`${endpoints.typeahead}?query=${value}`).then(response => {
        let options = [];
        (response?.data ?? []).forEach(option => {
          if (option && option.indexOf(value) > -1) {
            options.push({ label: option, value: option });
          }
        });
        this.setState({ filteredOptions: options });
      });
    }
  }

  render() {
    return (
      <Select
        onChange={e =>
          this.props.onChange ? this.props.onChange(e.value) : null
        }
        options={this.state.filteredOptions}
        onInputChange={value => this.filterOptions(value)}
        defaultValue={this.props.defaultValue}
        spacing="compact"
      />
    );
  }
}

export default UserSelect;
