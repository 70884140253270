import React, { Component } from "react";
import { API } from "./../../axios";
import { endpoints, routes } from "./../../endpoints";

import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import Form, { Field, FormSection, FormHeader } from "@atlaskit/form";
import { messages } from "./../../messages";
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled";
import { toast } from "react-toastify";
import "./style.scss";
class ChangePassword extends Component {
  async changePassword(data) {
    const params = data;
    this.setState({ isLoading: true });
    const response = await API.POST(endpoints.resetPassword, params);
    this.setState({ isLoading: false });
    if (response.success) {
      this.props.history.push(routes.home);
      toast.success(messages.passwordSuccess);
    } else {
      toast.error(messages.passwordFail);
    }
  }
  render() {
    return (
      <div className="form-wrapper change-password">
        <Form onSubmit={e => this.changePassword(e)}>
          {({ formProps }) => (
            <form className="form" {...formProps}>
              <div className="icon-lock">
                <LockFilledIcon primaryColor="white" size="large" />
              </div>
              <FormHeader title={messages.changePassword} />
              <FormSection>
                <Field name="oldpassword" label={messages.password} isRequired>
                  {({ fieldProps }) => (
                    <Textfield {...fieldProps} type="password" />
                  )}
                </Field>
                <Field name="newpassword" label={messages.password} isRequired>
                  {({ fieldProps }) => (
                    <Textfield {...fieldProps} type="password" />
                  )}
                </Field>
              </FormSection>
              <div className="button-wrapper">
                <Button appearance="primary" type="submit">
                  {messages.submit}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

export default ChangePassword;
