import React from "react";
import { API } from "./../../axios";
import { endpoints } from "./../../endpoints";
import { messages } from "./../../messages";
import Spinner from "@atlaskit/spinner";
import { toast } from "react-toastify";
import Table from "./table";
import Cookies from "js-cookie";

class Transaction extends Table {
  state = {
    data: [],
    isLoading: true,
    filteredRecord: [],
    page: 0
  };
  keys = [
    "name",
    "registration_number",
    "insurance_type",
    "expiry_date",
    "totalamount",
    "insurer_company",
    "policyno"
  ];
  headers = [
    messages.customerName,
    messages.uid,
    messages.insuranceType,
    messages.expiryDate,
    messages.totalAmount,
    messages.insurerCompany,
    messages.policyNo
  ];
  sortKey = "expiry_date";
  sortOrder = "asc";

  fields = [{ name: "insurerCompany", label: messages.insurerCompany }];
  componentDidMount() {
    this.role = localStorage.getItem("SKIV2role");

    if (this.role === "agent") {
      this.url = endpoints.agenttransaction;
      this.keys.push("rebate");
      this.headers.push(messages.commission);
    } else {
      this.url = endpoints.partnertransaction;
    }

    this.search();
  }
  async renew(id) {
    this.setState({ isLoading: true });
    const response = await API.POST(endpoints.renew + id);
    this.setState({ isLoading: false });
    if (response.success) {
      toast.success(messages.renewSuccess);
    } else {
      toast.error(messages.renewFailure);
    }
  }
  async download(id) {
    this.setState({ isLoading: true });
    const response = await API.GET(endpoints.fileDownload + id, {
      responseType: "blob"
    });
    this.setState({ isLoading: false });
    if (response.status === 200) {
      var a = document.createElement("a");
      var url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = "fileCopy";
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.success(messages.fileDownloadSuccess);
    } else {
      toast.error(messages.fileDownloadFailure);
    }
  }

  async search(data = {}) {
    let url = this.url;
    const filterData = [];
    Object.keys(data).map(key => {
      if (data[key]) {
        filterData.push({ attrName: key, attrValue: [data[key]] });
      }
    });
    const params = {
      filterData
    };
    this.setState({ isLoading: true });
    const response = await API.POST(url, params);
    this.setState({ isLoading: false });

    if (response.success) {
      const data = response.data.content.map(value => {
        return {
          ...value,
          registration_number: value.vehicle.registration_number,
          insurance_type: value.vehicle.insurance_type,
          name: value.vehicle.customer.name,
          expiry_date: value.vehicle.expiry_date
        };
      });
      this.setState({
        data: data,
        filteredRecord: data
      });
    }
  }
  render() {
    return (
      <div className="report">
        {this.state.isLoading && (
          <div className="spinner">
            <Spinner
              size="xlarge"
              isCompleting={!this.state.isLoading}
            ></Spinner>
          </div>
        )}
        {this.renderFilter()}
        {this.renderTitle()}
        {this.renderTable()}
      </div>
    );
  }
}

export default Transaction;
