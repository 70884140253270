import React, { Component } from "react";
import { API } from "./../../axios";
import { endpoints } from "./../../endpoints";

import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import Form, { Field, FormSection, FormHeader } from "@atlaskit/form";
import { messages } from "./../../messages";
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled";
import { toast } from "react-toastify";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import Cookies from "js-cookie";

class rightside extends Component {
  state = {
    forgot: false,
    showPassword: false
  };
  async forgotPassword(data) {
    const params = {};
    params.username = data.userName;
    const response = await API.POST(endpoints.forgot, params);
    if (response.success) {
      toast.success(messages.forgotSuccess);
      this.setState({ forgot: false });
    }
    toast.error(messages.invalidUsername);
  }
  async login(data) {
    const params = data;
    this.setState({ isLoading: true });
    const response = await API.POST(endpoints.login, params);
    this.setState({ isLoading: false });
    if (response.success) {
      localStorage.setItem("SKIV2role", response.data.role);
      Cookies.set("SKIV2token", response.data.token, {
        expires: 0.5
      });
      window.location.reload();
    } else {
      toast.error(messages.invalidCreds);
    }
  }
  render() {
    return (
      <div className="form-wrapper">
        <Form
          onSubmit={e =>
            this.state.forgot ? this.forgotPassword(e) : this.login(e)
          }
        >
          {({ formProps }) => (
            <form className="form" {...formProps}>
              <div className="icon-lock">
                <LockFilledIcon primaryColor="white" size="large" />
              </div>
              <FormHeader
                title={this.state.forgot ? messages.forgot : messages.login}
              />
              <FormSection>
                <Field name="userName" label={messages.username} isRequired>
                  {({ fieldProps }) => <Textfield {...fieldProps} />}
                </Field>
                {!this.state.forgot && (
                  <Field name="password" label={messages.password} isRequired>
                    {({ fieldProps }) => (
                      <div className="password">
                        <Textfield
                          {...fieldProps}
                          type={this.state.showPassword ? "text" : "password"}
                          elemAfterInput={
                            <Button
                              iconBefore={<WatchIcon />}
                              onClick={() =>
                                this.setState({
                                  showPassword: !this.state.showPassword
                                })
                              }
                            />
                          }
                        />
                      </div>
                    )}
                  </Field>
                )}
              </FormSection>
              <div className="button-wrapper">
                <Button appearance="primary" type="submit">
                  {this.state.forgot ? messages.submit : messages.login}
                </Button>
                <Button
                  appearance="link"
                  type="button"
                  className="forgot"
                  onClick={() => this.setState({ forgot: !this.state.forgot })}
                >
                  {this.state.forgot ? messages.loginpage : messages.forgot}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

export default rightside;
