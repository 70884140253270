import { TableExport } from "tableexport";

export const pdf = () => {
  const divContents = document.querySelector(".report-print-table");
  const head = document.head;

  var printWindow = window.open("", "", "height=400,width=800");
  printWindow.document.write("<html><head>");
  printWindow.document.write(head.innerHTML);
  printWindow.document.write("</head><body>");
  printWindow.document.write(divContents.outerHTML);
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
  printWindow.close();
};

export const csv = () => {
  var table = TableExport(document.getElementById("report-print-table"), {
    exportButtons: false
  });

  var exportData = table.getExportData();
  var csv = exportData["report-print-table"].csv; // Replace with the kind of file you want from the exportData
  table.export2file(
    csv.data,
    csv.mimeType,
    this.title,
    csv.fileExtension,
    csv.merges,
    csv.RTL
  );
};
export const excel = () => {
  var table = TableExport(document.getElementById("report-print-table"), {
    exportButtons: false
  });

  var exportData = table.getExportData();
  var xlsx = exportData["report-print-table"].xlsx; // Replace with the kind of file you want from the exportData
  table.export2file(
    xlsx.data,
    xlsx.mimeType,
    this.title,
    xlsx.fileExtension,
    xlsx.merges,
    xlsx.RTL,
    xlsx.sheetname
  );
};
export function filterByValue(array, string) {
  return array.filter(o =>
    Object.keys(o).some(k =>
      o[k]
        ? o[k]
            .toString()
            .toLowerCase()
            .includes(string.toLowerCase())
        : false
    )
  );
}
export function sort(key, order = "asc") {
  return function(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
