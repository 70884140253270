export const endpoints = {
  login: "/insta/nonadminlogin",
  forgot: "/insta/forgotpassword",
  agenttransaction: "/insta/agent/transaction",
  partnertransaction: "/insta/partner/transaction",
  commission: "/insta/agent/report",
  resetPassword: "/insta/resetpassword",
  fileDownload: "/insta/payments/downloadFile/",
  typeahead: "/insta/customerNameTypeAheadForPartner",
  renew: "/insta/payments/renew/",
  reportexport: "/insta/agent/report/export"
};
export const routes = {
  login: "/login",
  home: "/",
  myTransaction: "/transaction",
  commissionStatement: "/statement",
  changePassword: "/changePassword"
};
