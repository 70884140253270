import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class leftside extends Component {
  settings = {
    dots: true,
    autoplay: true,
    arrows: true
  };
  carouselText = [
    "Effortlessly add and edit customer information to maintain comprehensive and up-to-date records, ensuring you have all the necessary details at your fingertips.",
    "Seamlessly manage entities and transactions with just a few clicks, streamlining your workflow and allowing you to focus on growing your business.",
    "Stay on top of payment statuses and receive timely notifications about overdue payments, helping you maintain a healthy cash flow and avoid any financial surprises.",
    "Benefit from automatic SMS notifications for important updates and reminders, keeping you and your customers informed and engaged at all times.",
    "Generate detailed and insightful reports to gain a deeper understanding of your business performance, identify trends, and make data-driven decisions with confidence.",
    "Quickly create and send professional quotations, providing your clients with clear and accurate information, and improving your chances of closing deals faster.",
    "Easily track commissions to ensure accurate payouts, reducing administrative work and allowing you to focus on building relationships and driving sales.",
    "Set up expiry reminders to ensure you never miss an important renewal date, helping you provide exceptional service and maintain long-term client relationships."
];

  render() {
    return (
      <div>
        
        <div className="carousel-wrapper">
          <Slider {...this.settings}>
            {this.carouselText.map((text, key) => (
              <div className="slide-wrapper" key={key}>
                {text}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default leftside;
