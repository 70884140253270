import React from "react";
import { Route, Redirect } from "react-router-dom";
import { routes } from "../../endpoints";
import Cookies from "js-cookie";

const PrivateRoute = ({ component: Component }) => {
  const token = Cookies.get("SKIV2token");
  return (
    <Route
      render={props =>
        token ? <Component {...props} /> : <Redirect to={routes.login} />
      }
    />
  );
};

export default PrivateRoute;
