import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: `insta`
});
instance.interceptors.request.use(function(config) {
  const token = Cookies.get("SKIV2token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
const checkToken = error => {
  if (error && error.response && error.response.status === 401) {
    Cookies.remove("SKIV2token", { expires: 0.5 });
    localStorage.removeItem("SKIV2role");
    window.location.reload();
  }
};
export const API = {
  POST: async (url, params) => {
    try {
      const response = await instance.post(url, params);

      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return { success: false };
    }
  },
  PUT: async (url, params) => {
    try {
      const response = await instance.put(url, params);
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return { success: false };
    }
  },
  GET: async (url, config) => {
    try {
      const response = await instance.get(url, config);
      if (response.config.responseType === "blob") {
        return response;
      }
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return { success: false };
    }
  },
  DELETE: async url => {
    try {
      const response = await instance.delete(url);
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return { success: false };
    }
  }
};
