import React from "react";
import LeftSide from "./leftside";
import RightSide from "./rightside";
import "./style.scss";
import Cookies from "js-cookie";

class Login extends React.Component {
  constructor(props) {
    super(props);
    const token = Cookies.get("SKIV2token");
    if (token) {
      this.props.history.push("/home");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="login">
          <div className="left-side">
            <LeftSide />
          </div>
          <div className="right-side">
            <RightSide />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
