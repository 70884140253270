import React from "react";
import Pagination from "@atlaskit/pagination";

class CustomPagination extends React.Component {
  handleChange = (event, newPage) => {
    this.props.onChange(newPage - 1);
  };

  render() {
    const page = this.props.page || 0;
    const total = this.props.total;
    let noofPages = total === 0 ? 1 : Math.ceil(total / 10);
    const Pages = Array.apply(null, Array(noofPages)).map(
      (item, index) => index + 1
    );
    let start = total === 0 ? 0 : page * 10;
    const end = start + 10 <= total ? start + 10 : total;
    const actualTotal = this.props.actualTotal;
    const isFiltered = total !== actualTotal;
    if (total > 0) {
      start = start + 1;
    }
    return (
      <div className="pagination">
        <div>
          <span>
            Showing {start} to {end} of {total} entries
          </span>
          {isFiltered && (
            <span> (Filtered from {actualTotal} total entries) </span>
          )}
        </div>
        <Pagination
          pages={Pages}
          onChange={this.handleChange}
          selectedIndex={page}
        ></Pagination>
      </div>
    );
  }
}
export default CustomPagination;
