export const messages = {
  changePassword: "Change Password",
  commission: "Commission",
  commissionStatement: "Commission Statement",
  customerName: "Customer Name",
  datePlaceholder: "DD/MM/YYYY",
  download: "Download",
  expiryDate: "Expiry Date",
  fileDownloadSuccess: "Successfully downloaded the file",
  fileDownloadFailure: "Failed to download the file",
  forgot: "Forgot Password",
  forgotSuccess: "Login details sent to the mobile number registered.",
  insurerCompany: "Insurer Company",
  insuranceType: "Insurance Type",
  invalidCreds: "Invalid Credentials",
  invalidUsername: "Invalid Username",
  login: "Login",
  loginpage: "Back to Login",
  logout: "Logout",
  month: "Month",
  myTransaction: "My Transaction",
  noFileAvailable: "No File available",
  noRecordsFound: "No records found",
  password: "Password",
  passwordSuccess: "Password changed successfully",
  passwordFail: "Failed to change Password...",
  paymentDate: "Payment Date",
  paymentStatus: "Payment Status",
  phoneNo: "Phone No",
  policyNo: "Policy No.",
  renew: "Renew",
  renewFailure: "Failed to renew",
  renewSuccess: "Renewal submitted",
  submit: "Submit",
  totalAmount: "Total Amount",
  totalComission: "Total Comission",
  totalPolicy: "Total No. of Policies",
  uid: "Unique Identification No.",
  username: "Username",
  year: "Year"
};
