import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import createBrowserHistory from "./history";

import { Login, Home, Transaction, Commission, ChangePassword } from "./pages";
import { routes } from "./endpoints";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header/";
import Cookies from "js-cookie";

toast.configure();

class App extends React.Component {
  render() {
    const isLogin = Cookies.get("SKIV2token") ? true : false;
    return (
      <React.Fragment>
        <Router history={createBrowserHistory}>
          <div className="app-content">
            {isLogin && <Header />}
            <Switch>
              <Route path={routes.login} component={Login} />
              <PrivateRoute
                component={Transaction}
                path={routes.myTransaction}
              />
              <PrivateRoute
                component={Commission}
                path={routes.commissionStatement}
              />
              <PrivateRoute
                component={ChangePassword}
                path={routes.changePassword}
              />

              <PrivateRoute component={Home} path={routes.home} />
              <Redirect from="/" to={routes.home} />
            </Switch>
          </div>
        </Router>
        <ToastContainer
          hideProgressBar={true}
          position={toast.POSITION.BOTTOM_RIGHT}
        />
      </React.Fragment>
    );
  }
}

export default App;
