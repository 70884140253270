import React, { Component } from "react";
import { API } from "./../../axios";
import { endpoints } from "./../../endpoints";
import { messages } from "./../../messages";
import Spinner from "@atlaskit/spinner";
import { routes } from "./../../endpoints";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import Button from "@atlaskit/button";
import { toast } from "react-toastify";
import { exportCSVFile } from "../../helper";
class Commission extends Component {
  state = {
    data: [],
    isLoading: true
  };
  headers = [
    messages.year,
    messages.month,
    messages.totalPolicy,
    messages.totalAmount,
    messages.totalComission
  ];
  fileHeaders = [
    messages.customerName,
    messages.uid,
    messages.insuranceType,
    messages.expiryDate,
    messages.totalAmount,
    messages.insurerCompany,
    messages.policyNo,
    messages.commission
  ];

  componentDidMount() {
    const role = localStorage.getItem("SKIV2role");

    if (role === "agent") {
      this.search();
    } else {
      this.props.history.push(routes.home);
    }
  }
  async download(id) {
    this.setState({ isLoading: true });
    const response = await API.POST(endpoints.reportexport, {
      month: id[1],
      year: id[0]
    });
    this.setState({ isLoading: false });
    if (response.success) {
      const itemsNotFormatted = [];
      response.data.map(value =>
        itemsNotFormatted.push({
          name: value.vehicle.customer.name,
          insurerCompany: value.insurer_company,
          policyno: value.policyno,
          ...value,
          registration_number: value.vehicle.registration_number,
          insurance_type: value.vehicle.insurance_type,
          expiry_date: value.vehicle.expiry_date
        })
      );

      const itemsFormatted = [];
      itemsNotFormatted.forEach(item => {
        itemsFormatted.push({
          customerName: item.name,
          registration_number: item.registration_number,
          insurance_type: item.insurance_type,
          expiry_date: item.expiry_date,
          totalamount: item.totalamount,
          insurerCompany: item.insurer_company,
          policyno: item.policyno,
          commission: item.commission
        });
      });
      exportCSVFile(this.fileHeaders, itemsFormatted, "report");
    } else {
      toast.error(messages.fileDownloadFailure);
    }
  }

  async search() {
    this.setState({ isLoading: true });
    const params = {
      filter: {}
    };
    const response = await API.POST(endpoints.commission, params);
    this.setState({ isLoading: false });

    if (response.success) {
      this.setState({ rows: response.data.content });
    }
  }

  renderHeader() {
    const headers = this.headers;
    return (
      <tr>
        {headers.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
        <th>{messages.download}</th>
      </tr>
    );
  }
  renderBody() {
    const rows = this.state.rows || [];
    const keys = this.keys;
    if (rows.length === 0) {
      return (
        <tr>
          <td colSpan={this.headers.length}>No records found</td>
        </tr>
      );
    }
    return rows.map((row, index) => (
      <tr key={index}>
        {row.map((value, index) => {
          return <td key={index}>{value}</td>;
        })}
        <td>
          <Button
            iconBefore={<DownloadIcon />}
            appearance="subtle"
            onClick={() => this.download(row)}
          ></Button>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="report">
        {this.state.isLoading ? (
          <div className="spinner">
            <Spinner size="xlarge"></Spinner>
          </div>
        ) : (
          <div className="table-wrapper">
            <table className="update-table">
              <thead>{this.renderHeader()}</thead>
              <tbody>{this.renderBody()}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default Commission;
