import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Cookies from "js-cookie";

class Home extends React.Component {
  state = {
    showPassword: false
  };
  constructor(props) {
    super(props);
    const token = Cookies.get("SKIV2token");
    if (token) {
      this.props.history.push("/home");
    }
  }

  render() {
    var settings = {
      dots: true,
      autoplay: true
    };
    return (
      <React.Fragment>
        <div className="home">
          <Slider {...settings}>
            <div className="slide-wrapper">
              <img src="../insta_001.jpg" alt="slider" />
            </div>
            <div className="slide-wrapper">
              <img src="../insta_002.jpg" alt="slider" />
            </div>
          </Slider>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
