import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "./../../endpoints";
import { messages } from "./../../messages";
import "./style.scss";
import Button from "@atlaskit/button";
import SignOutIcon from "@atlaskit/icon/glyph/sign-out";
import PersonIcon from "@atlaskit/icon/glyph/person";

import Popup from "@atlaskit/popup";
import Cookies from "js-cookie";

class Header extends Component {
  state = { isOpen: false };
  logout() {
    Cookies.remove("SKIV2token", {
      expires: 0.5
    });
    window.location.reload();
  }
  render() {
    const role = localStorage.getItem("SKIV2role");
    return (
      <header>
        <nav>
          <NavLink to={routes.home}>
            <img src="./companylogo.png" className="logo" alt="company logo" />
          </NavLink>
          <ul>
            <li className="link">
              <NavLink to={routes.myTransaction} activeClassName="selected">
                {messages.myTransaction}
              </NavLink>
            </li>
            {role === "agent" && (
              <li className="link">
                <NavLink
                  to={routes.commissionStatement}
                  activeClassName="selected"
                >
                  {messages.commissionStatement}
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
        <div className="logout">
          <Popup
            isOpen={this.state.isOpen}
            onClose={() => this.setState({ isOpen: false })}
            placement="bottom-end"
            content={() => (
              <div className="popup-content">
                <Button appearance="link">
                  <NavLink to={routes.changePassword}>
                    {messages.changePassword}
                  </NavLink>
                </Button>

                <Button
                  appearance="link"
                  onClick={() => this.logout()}
                  iconBefore={<SignOutIcon primaryColor="#1890ff" />}
                >
                  {messages.logout}
                </Button>
              </div>
            )}
            trigger={triggerProps => (
              <Button
                {...triggerProps}
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                iconBefore={<PersonIcon />}
              />
            )}
          />
        </div>
      </header>
    );
  }
}

export default Header;
