import React from "react";
import Form, { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import SearchIcon from "@atlaskit/icon/glyph/search";
import { toast } from "react-toastify";
import CustomPagination from "./../../components/Pagination";
import Spinner from "@atlaskit/spinner";
import { messages } from "../../messages";
import { pdf, csv, excel, filterByValue, sort } from "./../../helper";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { DatePicker } from "@atlaskit/datetime-picker";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import UserSelect from "./../../components/UserSelect";
class Table extends React.Component {
  applysort(key) {
    if (key === this.sortKey) {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
    } else {
      this.sortOrder = "asc";
    }
    this.sortKey = key;
    const record = this.state.filteredRecord.sort(sort(key, this.sortOrder));
    this.setState({ filteredRecord: record, page: 0 });
  }
  filterData(string) {
    let records = filterByValue(this.state.data, string);
    records = records.sort(sort(this.sortKey, this.sortOrder));
    this.setState({ filteredRecord: records, page: 0 });
  }
  copy() {
    const table = document.querySelector(".report-print-table");
    table.style.display = "table";
    const range = document.createRange(); // create new range object
    range.selectNodeContents(table); // set range to encompass desired element text
    var selection = window.getSelection(); // get Selection object from currently user selected text
    selection.removeAllRanges(); // unselect any user selected text (if any)
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges(); // unselect any user selected text (if any)
    table.style.display = "none";
    toast.success("Text copied to clipboard");
  }
  csv() {
    csv();
  }
  excel() {
    excel();
  }
  pdf() {
    pdf();
  }
  renderFilter() {
    return (
      <div className="filter">
        <Form
          onSubmit={e => {
            this.search(e);
          }}
        >
          {({ formProps }) => (
            <form {...formProps} className="filter-form">
              <div className="top-section">
                <h3 className="filter-heading">Filters</h3>
                <Button appearance="primary" type="submit">
                  {messages.submit}
                </Button>
              </div>
              <div className="filter-fields">
                <div className="field-wrapper">
                  <Field name="startDate" label="Start Date">
                    {({ fieldProps }) => (
                      <DatePicker
                        {...fieldProps}
                        placeholder={messages.datePlaceholder}
                        spacing="compact"
                        dateFormat="DD/MM/YYYY"
                        locale="en-AU"
                      />
                    )}
                  </Field>
                </div>
                <div className="field-wrapper">
                  <Field name="endDate" label="End Date">
                    {({ fieldProps }) => (
                      <DatePicker
                        {...fieldProps}
                        placeholder={messages.datePlaceholder}
                        spacing="compact"
                        dateFormat="DD/MM/YYYY"
                        locale="en-AU"
                      />
                    )}
                  </Field>
                </div>

                {this.fields.map((field, index) => (
                  <div className="field-wrapper" key={index}>
                    <Field name={field.name} label={field.label}>
                      {({ fieldProps }) => (
                        <Textfield {...fieldProps} isCompact={true} />
                      )}
                    </Field>
                  </div>
                ))}
                {this.role === "partner" && (
                  <div className="field-wrapper">
                    <Field name="customerName" label={messages.customerName}>
                      {({ fieldProps }) => <UserSelect {...fieldProps} />}
                    </Field>
                  </div>
                )}
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
  renderTable = () => {
    return (
      <React.Fragment>
        <div className="table-wrapper">
          <table className="report-table" id="report-table">
            <thead>
              <tr>
                {this.keys.map((key, index) => (
                  <th
                    key={index}
                    onClick={() => this.applysort(key)}
                    className={
                      this.sortKey === key
                        ? this.sortOrder === "asc"
                          ? "asc"
                          : "desc"
                        : ""
                    }
                  >
                    <span>{this.headers[index]}</span>
                  </th>
                ))}
                <th>
                  <span>{messages.download}</span>
                </th>
                {this.role === "partner" && (
                  <th>
                    <span>{messages.renew}</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </table>
          <table
            className="report-print-table report-table"
            id="report-print-table"
          >
            <thead>
              <tr>
                {this.keys.map((key, index) => (
                  <th key={index}>
                    <span>{this.headers[index]}</span>
                  </th>
                ))}
                <th>
                  <span>{messages.download}</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.renderPrintRows()}</tbody>
          </table>
        </div>
        {this.renderPagination()}
      </React.Fragment>
    );
  };
  renderRows() {
    if (this.state.filteredRecord.length === 0) {
      return (
        <tr>
          <td colSpan={this.keys.length}>{messages.noRecordsFound}</td>
        </tr>
      );
    }
    const start = this.state.page * 10;
    const end = start + 10;

    const rows = this.state.filteredRecord.slice(start, end);
    return rows.map((data, index) => (
      <tr key={index} className={index % 2 === 0 ? "odd" : "even"}>
        {this.keys.map((key, rowindex) => (
          <td key={rowindex}>{data[key]}</td>
        ))}
        <td>
          {data["fileUuid"] ? (
            <Button
              iconBefore={<DownloadIcon />}
              appearance="subtle"
              onClick={() => this.download(data["fileUuid"])}
            ></Button>
          ) : (
            messages.noFileAvailable
          )}
        </td>
        {this.role === "partner" && (
          <td>
            <Button
              iconBefore={<RefreshIcon />}
              appearance="subtle"
              onClick={() => this.renew(data["paymentid"])}
            ></Button>
          </td>
        )}
      </tr>
    ));
  }
  renderPrintRows() {
    if (this.state.data.length === 0) {
      return (
        <tr>
          <td colSpan={this.keys.length}>{messages.noRecordsFound}</td>
        </tr>
      );
    }

    const rows = this.state.data;
    return rows.map((data, index) => (
      <tr key={index} className={index % 2 === 0 ? "odd" : "even"}>
        {this.keys.map((key, rowindex) => (
          <td key={rowindex}>{data[key]}</td>
        ))}
      </tr>
    ));
  }
  renderTitle() {
    return (
      <div className="report-title">
        <div className="right">
          <Button onClick={() => this.copy()}>Copy</Button>
          <Button onClick={() => this.csv()}>CSV</Button>
          <Button onClick={() => this.excel()}>Excel</Button>
          <Button onClick={() => this.pdf()}>Print</Button>
          <Textfield
            elemAfterInput={<SearchIcon />}
            width="200"
            placeholder="Search"
            onChange={e => this.filterData(e.target.value)}
          ></Textfield>
        </div>
      </div>
    );
  }
  renderPagination() {
    return (
      <CustomPagination
        page={this.state.page}
        total={this.state.filteredRecord.length}
        actualTotal={this.state.data.length}
        onChange={page => this.onPageChange(page)}
      ></CustomPagination>
    );
  }
  onPageChange(page) {
    this.setState({ page: page });
  }
  render() {
    return (
      <div className="report">
        {this.state.isLoading && (
          <div className="spinner">
            <Spinner
              size="xlarge"
              isCompleting={!this.state.isLoading}
            ></Spinner>
          </div>
        )}

        {this.renderTitle()}
        {this.renderFilter()}
        {this.renderTable()}
      </div>
    );
  }
}
export default Table;
